<template>
    <div>
        <div
            v-if="!dealerFacebookOfflineEventSetAnnotation"
            class="text-xs-center ma-5">
            <div
                class="red--text">
                No offline event set has been configured on this account.
                Please contact support to resolve this issue
            </div>
        </div>

        <div
            v-if="!hasCurrentEventTypeEvents"
            class="pa-5 text-xs-center">
            <h3 v-if="lastOfflineEventDate">
                You haven't uploaded {{ eventType.name | camelCaseToWords | lowercase }}
                events since {{ lastEvent }}.
            </h3>
            <h3 v-else>
                There is not enough available offline data to calculate
                {{ eventType.name | camelCaseToWords | lowercase }} event attribution data.
            </h3>
            <p>
                Please <router-link :to="{ name: 'dealer-offline-events'}">
                    upload events
                </router-link> up to {{ endDate }} to view sales attribution for this period.
            </p>
        </div>
        <div
            v-else
            class="wrapper">
            <div class="top">
                <div class="top-title">
                    {{ dateRangeFormatted }} Matchback
                </div>
                <div class="top-link">
                    <router-link :to="{ name: 'home'}">
                        View Dashboard
                    </router-link>
                </div>
            </div>
            <div class="head">
                <div class="head-container">
                    <div class="head-block">
                        <div class="head-icon">
                            <icon
                                name="money-sack"
                                size="25" />
                        </div>
                        <div class="head-num">
                            {{ totalEvents }}
                        </div>
                        <div class="head-desc">
                            Total sales from your sold report
                        </div>
                    </div>
                </div>
                <div class="head-container">
                    <div class="head-block">
                        <div class="head-icon">
                            <icon
                                name="meta"
                                size="25" />
                        </div>
                        <div class="head-num">
                            {{ totalAttributedEvents }}
                        </div>
                        <div class="head-desc">
                            Sales attributed to Meta ads
                        </div>
                    </div>
                </div>
            </div>
            <div class="window">
                <div class="window-customer">
                    Customer Actions
                </div>
                <div class="window-title">
                    Purchase Window (in days)
                </div>
                <div class="days">
                    <div class="days-left">
                        <div class="days-block">
                            <div class="days-block-top">
                                &nbsp;
                            </div>
                            <div class="days-block-middle">
                                <div class="days-sm-title">
                                    Viewed Ad
                                </div>
                                <div class="days-desc">
                                    Viewed Ad And Did Not Click
                                </div>
                            </div>
                            <div class="days-block-bottom">
                                <div class="days-sm-title">
                                    Clicked Ad
                                </div>
                                <div class="days-desc">
                                    Viewed and clicked on Ad
                                </div>
                            </div>
                        </div>
                        <div class="days-block">
                            <div class="days-block-top">
                                1 Day
                            </div>
                            <div class="days-block-middle">
                                <div class="days-block-num">
                                    {{ attributionMetrics['1d_view'].formatted }}
                                </div>
                                <div class="days-block-title">
                                    Sales
                                </div>
                            </div>
                            <div class="days-block-bottom">
                                <div class="days-block-num">
                                    {{ attributionMetrics['1d_click'].formatted }}
                                </div>
                                <div class="days-block-title">
                                    Sales
                                </div>
                            </div>
                        </div>
                        <div class="days-block">
                            <div class="days-block-top">
                                7 Days
                            </div>
                            <div class="days-block-middle">
                                <div class="days-block-num">
                                    {{ attributionMetrics['7d_view'].formatted }}
                                </div>
                                <div class="days-block-title">
                                    Sales
                                </div>
                            </div>
                            <div class="days-block-bottom">
                                <div class="days-block-num">
                                    {{ attributionMetrics['7d_click'].formatted }}
                                </div>
                                <div class="days-block-title">
                                    Sales
                                </div>
                            </div>
                        </div>
                        <div class="days-block">
                            <div class="days-block-top">
                                28 Days
                            </div>
                            <div class="days-block-middle">
                                <div class="days-block-num">
                                    {{ attributionMetrics['28d_view'].formatted }}
                                </div>
                                <div class="days-block-title">
                                    Sales
                                </div>
                                <div class="days-block-desc">
                                    Total Sales attributed to <b>Viewing</b> an ad and
                                    buying a vehicle in 28 days
                                </div>
                            </div>
                            <div class="days-block-bottom">
                                <div class="days-block-num">
                                    {{ attributionMetrics['28d_click'].formatted }}
                                </div>
                                <div class="days-block-title">
                                    Sales
                                </div>
                                <div class="days-block-desc">
                                    Total Sales attributed to <b>Clicking</b> an ad and
                                    buying a vehicle in 28 days
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="days-right">
                        <div
                            class="right-block"
                            :class="{'mt-3': meta.extended_info}">
                            <div v-if="!meta.extended_info">
                                <icon
                                    name="meta"
                                    size="25" />
                            </div>
                            <div class="right-num">
                                {{ totalAttributedEvents }}
                            </div>
                            <div class="right-title">
                                Sales
                            </div>
                            <div class="right-desc">
                                Total sales attributed to Meta ads
                            </div>
                        </div>
                        <div
                            v-if="meta.extended_info"
                            class="right-block mt-4">
                            <div class="right-num">
                                <small>{{ costPerEvent }}</small>
                            </div>
                            <div class="right-title">
                                Cost Per <br> Unit Sold
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="currentAgency.tech_support_from_email"
                class="questions">
                <div class="questions-title">
                    Have questions?
                </div>
                <div class="questions-desc">
                    Reach our Performance Team at <a :href="`mailto:${currentAgency.tech_support_from_email}`">{{ currentAgency.tech_support_from_email }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FACEBOOK_OFFLINE_EVENT_TYPES, FACEBOOK_ATTRIBUTION_WINDOWS } from '@/helpers/globals';
import { mapGetters, mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import moment from 'moment';
import getNumberFormats from '@/helpers/numberFormats';
import numeral from 'numeral';

export default {
    name: 'OfflineEventsMatchback',
    components: {
        Icon,
    },
    props: {
        meta: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            numberFormats: getNumberFormats(),
            eventType: null
        };
    },
    computed: {
        ...mapState({
            dateRange: (state) => state.metrics.dateRange,
            attribution: (state) => state.metrics.facebook.ads.attribution,
            rawEvents: (state) => state.metrics.facebook.ads.offlineEvents,
            currentAgency: (state) => state.agency.currentAgency,
            currentRawEvents: (state) => state.metrics.facebook.ads.offlineEventsCurrent
        }),
        ...mapGetters(['dealerFacebookOfflineEventSetAnnotation']),
        ...mapGetters('metrics/facebook/ads', {
            metricTotals: 'metricsTotalsFormatted',
        }),
        dateRangeFormatted() {
            return moment(this.dateRange.startDate).format('MMM D') + ' - ' + moment(this.dateRange.endDate).format('MMM D, YYYY');
        },
        clickAttribution() {
            return this.attribution.offline.click + '_click';
        },
        viewAttribution() {
            return this.attribution.offline.view + '_view';
        },
        hasCurrentEventTypeEvents() {
            return this.currentEvents.filter(event => event.count > 0).length > 0;
        },
        eventTypeTotals() {
            const totals = {};
            this.rawEvents.forEach(event => {
                if (totals[event.event]) {
                    totals[event.event] += event.count;
                } else {
                    totals[event.event] = event.count;
                }
            });
            return totals;
        },
        eventTypes() {
            return FACEBOOK_OFFLINE_EVENT_TYPES.map(eventType => {
                const newType = { ...eventType }; // Avoid infinite state updates
                newType.count = this.eventTypeTotals[newType.name] || 0;
                newType.label = `${newType.name} (${newType.count})`;
                return newType;
            });
        },
        attributionMetrics() {
            const metrics = {};
            FACEBOOK_ATTRIBUTION_WINDOWS.forEach(attributionWindow => {
                metrics[attributionWindow.key] = this.metricTotals[`offline_${this.eventType.key}_${attributionWindow.key}`];
            });
            return metrics;
        },
        totalAttributedEvents() {
            return (
                (this.metricTotals[`offline_${this.eventType.key}_${this.clickAttribution}`]?.value || 0)
                +
                (this.metricTotals[`offline_${this.eventType.key}_${this.viewAttribution}`]?.value || 0)
            );
        },
        costPerEvent() {
            const costPer = this.metricTotals.spend.value / this.totalAttributedEvents;
            return isFinite(costPer) ? numeral(costPer).format(this.numberFormats.currency) : '-';
        },
        totalEvents() {
            if (!this.eventType) {
                return 0;
            }
            return this.eventTypeTotals[this.eventType.name] ? this.eventTypeTotals[this.eventType.name] : 0;
        },
        currentEvents() {
            return this.currentRawEvents.filter(e => e.event == this.eventType.name);
        },
        lastOfflineEventDate() {
            // Find the days in the array with more than one event
            const activeEvents = this.currentEvents.filter(event => event.count > 0);

            if (!activeEvents.length) {
                return null;
            }
            const lastEvent = activeEvents[activeEvents.length - 1];
            return moment.unix(lastEvent.time).toDate();
        },
        canViewOfflineAttribution() {
            if (this.lastOfflineEventDate == null) {
                return false;
            }
            const diff = moment(this.dateRange.endDate).diff(this.lastOfflineEventDate, 'days');
            return diff <= 0;
        },
        lastEvent() {
            return moment(this.lastOfflineEventDate).format('MM/DD/Y');
        },
        endDate() {
            return moment(this.dateRange.endDate).format('M/D/Y');
        }
    },
    created() {
        this.eventType = this.eventTypes.find(type => type.key === 'purchase');
    }
};
</script>

<style lang="scss" scoped>
.wrapper {
    font-family: 'Open Sans';
    transform: scale(0.93) translateY(-25px);
}
.top {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding-left: 25px;
}
.top-title {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}
.top-link {
    color: $blue;
    text-decoration: underline;
    transform: translateY(-40px);
    a {
        color: $blue;
    }
}
.head {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 25px;
    border-bottom: 1px solid $gray-light;
    margin: 0 25px;
}
.head-block {
    text-align: center;
    width: 130px;
    margin: 0 60px;
}
.head-container {
    padding-top: 15px;
    &:first-of-type {
        border-right: 1px solid $gray-light;
    }
}
.head-num {
    color: $blue;
    font-weight: 600;
    font-size: 48px;
    line-height: 66px;
}
.head-desc {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 14px;
}
.window {
    position: relative;
    margin-left: 60px;
}
.window-customer {
    position: absolute;
    left: -85px;
    top: 60%;
    color: $blue;
    font-size: 24px;
    transform: rotate(-90deg);
}
.window-title {
    color: $blue;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
}
.days {
    display: flex;
    justify-content: center;
}
.days-left {
    display: flex;
}
.days-block {
    width: 170px;
    text-align: center;
    &:nth-of-type(1) {
        width: 125px;
        height: 100%;
        .days-block-middle,
        .days-block-bottom {
            border-right: 1px solid #8E9091;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 15px;
            font-size: 12px;
        }
    }
    &:nth-of-type(2) {
        .days-block-middle,
        .days-block-bottom {
            border-right: 1px solid #8E9091;
        }
    }
    &:last-of-type {
        position: relative;
        .days-block-top {
            position: relative;
            z-index: 2;
        }
        .days-block-middle {
            background: #B4CBD3;
            position: relative;
            z-index: 2;
        }
        .days-block-bottom {
            background: #C4DBE3;
            position: relative;
            z-index: 2;
        }
        &:after {
            content: '';
            position: absolute;
            background: #CAE5EE;
            border-radius: 25px;
            width: 225%;
            height: calc(100% + 30px);
            top: 0;
            left: 0;
            z-index: 0;
        }
    }
}
.days-block-top {
    padding: 10px;
    text-transform: uppercase;
    font-size: 16px;
}
.days-block-middle {
    background: #D3D5D6;
    height: 140px;
    padding-top: 10px;
}
.days-block-bottom {
    background: #E3E4E6;
    height: 140px;
    padding-top: 10px;
}
.days-sm-title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 5px;
}
.days-desc {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 14px;
}
.days-block-num {
    color: $blue;
    font-weight: 600;
    font-size: 48px;
    line-height: 48px;
}
.days-block-title {
    text-transform: uppercase;
}
.days-block-desc {
    font-size: 9px;
    text-transform: uppercase;
    margin-top: 8px;
    padding: 0 5px;
}
.days-right {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    flex-direction: column;
    justify-content: center;
}
.right-block {
    text-align: center;
    width: 150px;
    margin-left: 30px;
}
.right-title {
    text-transform: uppercase;
    margin-bottom: 10px;
    margin-top: -10px;
}
.right-num {
    color: $blue;
    font-weight: 600;
    font-size: 48px;
}
.right-desc {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 14px;
}
.questions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    a {
        color: $blue;
    }
}
.questions-title {
    margin-right: 30px;
    color: $blue;
    font-size: 18px;
}
</style>
